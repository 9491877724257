var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "index-main",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "warp"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.isMobile ? 'wap/h5-' : ''}logo.png`)
    }
  })]), _c('div', {
    staticClass: "socials"
  }, [_c('div', {
    staticClass: "btn"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-telegram"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('telegram');
      }
    }
  })], 1), _c('div', {
    staticClass: "btn"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-x"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('x');
      }
    }
  })], 1), _c('div', {
    staticClass: "btn"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-youtube"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('youtube');
      }
    }
  })], 1)])])]), _c('div', {
    staticClass: "section-1"
  }, [_c('div', {
    staticClass: "warp"
  }, [_c('div', {
    staticClass: "section-1-left wow animate__animated animate__fadeInLeft"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("Web3 Gamified Social")]), _c('div', {
    staticClass: "tit gradient-text"
  }, [_vm._v("Learning Platform")]), _c('div', {
    staticClass: "brief"
  }, [_vm._v("Tap to Earn + Knowledge Mining, Learning"), _vm.isMobile ? _c('br') : _vm._e(), _vm._v("Through Entertainment.")]), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onSkip('game');
      }
    }
  }, [_vm._v("Start Game")]), _c('div', {
    on: {
      "click": function ($event) {
        return _vm.onSkip('telegram');
      }
    }
  }, [_vm._v("Learn more "), _c('svg-icon', {
    attrs: {
      "name": "icon-arrow1"
    }
  })], 1)])]), _c('div', {
    staticClass: "section-1-right wow animate__animated animate__fadeInRight"
  }, [_c('div', {
    staticClass: "phone1"
  }, [_vm._m(0), _c('img', {
    attrs: {
      "src": require(`@images/${_vm.isMobile ? 'wap/h5-' : ''}phone1.png`)
    }
  })]), _c('div', {
    staticClass: "phone2"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.isMobile ? 'wap/h5-' : ''}phone2.png`)
    }
  })])])])]), _c('div', {
    staticClass: "section-2"
  }, [_c('div', {
    staticClass: "warp"
  }, [_c('div', {
    staticClass: "section-2-left wow animate__animated animate__fadeInLeft"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("TapCoins")]), _vm._m(1), _c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onSkip('game');
      }
    }
  }, [_vm._v(" Join now "), _c('svg-icon', {
    attrs: {
      "name": "icon-arrow1"
    }
  })], 1)]), _vm._m(2)])]), _c('div', {
    staticClass: "section-3"
  }, [_c('div', {
    staticClass: "section-3-main"
  }, [_c('div', {
    staticClass: "warp"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("Learn About TapCoins")]), _c('div', {
    staticClass: "brief"
  }, [_vm._v(" Enjoy fun gaming experiences and social mechanisms to "), _vm.isMobile ? _c('br') : _vm._e(), _vm._v("achieve dual growth "), !_vm.isMobile ? _c('br') : _vm._e(), _vm._v(" in knowledge and wealth. ")]), _c('div', {
    staticClass: "items"
  }, [_c('div', {
    staticClass: "item item-1 wow animate__animated animate__fadeInDown"
  }, [_vm.isMobile ? _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "t"
  }, [_vm._v("Knowledge Dissemination")]), _vm._m(3)]) : _vm._e(), _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.isMobile ? 'wap/h5-' : ''}section-3-item1.png`)
    }
  })]), !_vm.isMobile ? _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "t"
  }, [_vm._v("Knowledge Dissemination")]), _vm._m(4)]) : _vm._e()]), _c('div', {
    staticClass: "item item-2 wow animate__animated animate__fadeInUp"
  }, [!_vm.isMobile ? _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "t"
  }, [_vm._v("Innovative Incentives")]), _vm._m(5)]) : _vm._e(), _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.isMobile ? 'wap/h5-' : ''}section-3-item2.png`)
    }
  })]), _vm.isMobile ? _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "t"
  }, [_vm._v("Innovative Incentives")]), _vm._m(6)]) : _vm._e()]), _c('div', {
    staticClass: "item item-3 wow animate__animated animate__fadeInDown"
  }, [_vm.isMobile ? _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "t"
  }, [_vm._v("Gamified Social Learning")]), _vm._m(7)]) : _vm._e(), _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/${_vm.isMobile ? 'wap/h5-' : ''}section-3-item3.png`)
    }
  })]), !_vm.isMobile ? _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "t"
  }, [_vm._v("Gamified Social Learning")]), _vm._m(8)]) : _vm._e()])])])])]), _c('div', {
    staticClass: "section-4"
  }, [_c('div', {
    staticClass: "section-4-main"
  }, [_c('div', {
    staticClass: "warp"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("Core Gameplay")]), _c('div', {
    staticClass: "brief"
  }, [_vm._v("Here, you can enjoy gaming while continuously learning and growing!")]), _c('div', {
    staticClass: "items wow animate__animated animate__fadeInUp"
  }, [_vm._m(9), _vm._m(10), _c('div', {
    staticClass: "item item-3"
  }, [_c('div', {
    staticClass: "t"
  }, [_vm._v("Social Interaction")]), _vm._m(11), _c('div', {
    staticClass: "coin"
  }, [_c('img', {
    attrs: {
      "src": require(`@images/coin.png`)
    }
  })])])]), _c('div', {
    staticClass: "btns"
  }, [_c('div', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.onSkip('game');
      }
    }
  }, [_vm._v("Join Now")])])])])]), _vm._m(12), _c('div', {
    staticClass: "footer"
  }, [_vm._m(13), _c('div', {
    staticClass: "sub-tit"
  }, [_vm._v("Explore Web3, Earn Rewards!")]), _c('div', {
    staticClass: "socials"
  }, [_c('div', {
    staticClass: "btn"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-telegram"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('telegram');
      }
    }
  })], 1), _c('div', {
    staticClass: "btn"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-x"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('x');
      }
    }
  })], 1), _c('div', {
    staticClass: "btn"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-youtube"
    },
    on: {
      "click": function ($event) {
        return _vm.onSkip('youtube');
      }
    }
  })], 1)])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "nums wow animate__animated animate__bounceIn"
  }, [_c('div', {
    staticClass: "num num-1"
  }, [_vm._v("+80")]), _c('div', {
    staticClass: "num num-2"
  }, [_vm._v("+200")]), _c('div', {
    staticClass: "num num-3"
  }, [_vm._v("+10")]), _c('div', {
    staticClass: "num num-4"
  }, [_vm._v("+500")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "brief"
  }, [_vm._v("Tap to collect coins."), _c('br'), _vm._v("Earn up to 10X more by boost.")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "section-2-right wow animate__animated animate__fadeInRight"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("Airdrop")]), _c('div', {
    staticClass: "soon"
  }, [_vm._v("Coming Soon")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "b"
  }, [_vm._v("Easily learn Web3 knowledge "), _c('br'), _vm._v(" for everyone")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "b"
  }, [_vm._v("Easily learn Web3 knowledge "), _c('br'), _vm._v(" for everyone")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "b"
  }, [_vm._v("Boost learning motivation with fun"), _c('br'), _vm._v(" incentive mechanisms")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "b"
  }, [_vm._v("Boost learning motivation with fun"), _c('br'), _vm._v(" incentive mechanisms")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "b"
  }, [_vm._v("Build a community combining entertainment "), _c('br'), _vm._v(" and education")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "b"
  }, [_vm._v("Build a community combining entertainment "), _c('br'), _vm._v(" and education")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item item-1"
  }, [_c('div', {
    staticClass: "t"
  }, [_vm._v("Tap to Earn")]), _c('div', {
    staticClass: "b"
  }, [_vm._v("Earn coins through"), _c('br'), _vm._v("continuous tapping, easy"), _c('br'), _vm._v("and fun")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "item item-2"
  }, [_c('div', {
    staticClass: "t"
  }, [_vm._v("Knowledge Mining")]), _c('div', {
    staticClass: "b"
  }, [_vm._v("Learn Web3 knowledge and"), _c('br'), _vm._v("complete tasks to unlock"), _c('br'), _vm._v("more rewards")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "b"
  }, [_vm._v("Invite friends or forward"), _c('br'), _vm._v("to expand social network"), _c('br'), _vm._v("and income")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "section-5"
  }, [_c('div', {
    staticClass: "warp"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("TAPCOINS ROADMAP")]), _c('div', {
    staticClass: "step"
  }, [_c('div', {
    staticClass: "items"
  }, [_c('div', {
    staticClass: "item item-1 wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.2s"
    }
  }, [_c('div', {
    staticClass: "t"
  }, [_vm._v("Getting Started with Web3 Gaming")]), _c('div', {
    staticClass: "sub-items"
  }, [_c('div', {
    staticClass: "sub-item"
  }, [_vm._v("TON Miniapp Launched")]), _c('div', {
    staticClass: "sub-item"
  }, [_vm._v("Dedicated to WEB3 Knowledge Learning")]), _c('div', {
    staticClass: "sub-item"
  }, [_vm._v("Introducing Tap to Earn Mode")]), _c('div', {
    staticClass: "sub-item"
  }, [_vm._v("Launching Invite and Earn Mechanism")])])]), _c('div', {
    staticClass: "item item-2 wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_c('div', {
    staticClass: "t"
  }, [_vm._v("Immersive Learning Experience")]), _c('div', {
    staticClass: "sub-items"
  }, [_c('div', {
    staticClass: "sub-item"
  }, [_vm._v("Launching Web3 Knowledge Mining Feature")]), _c('div', {
    staticClass: "sub-item"
  }, [_vm._v("Adding Daily Bounty Activities")]), _c('div', {
    staticClass: "sub-item"
  }, [_vm._v("Expanding Knowledge Base")]), _c('div', {
    staticClass: "sub-item"
  }, [_vm._v("Organizing Regular Community Events")]), _c('div', {
    staticClass: "sub-item"
  }, [_vm._v("Introducing Achievement System")]), _c('div', {
    staticClass: "sub-item"
  }, [_vm._v("Integrating WEB3 Video Tutorials on YouTube")]), _c('div', {
    staticClass: "sub-item"
  }, [_vm._v("Launching Video Code Interactive Gameplay")])])]), _c('div', {
    staticClass: "item item-3 wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.6s"
    }
  }, [_c('div', {
    staticClass: "t"
  }, [_vm._v("Connecting with More Ecosystems")]), _c('div', {
    staticClass: "sub-items"
  }, [_c('div', {
    staticClass: "sub-item"
  }, [_vm._v("Collaborating with More TON/WEB3/WEB2"), _c('br'), _vm._v("Ecosystem Partners")]), _c('div', {
    staticClass: "sub-item"
  }, [_vm._v("Launching Task Airdrop (USDT/TON) Activities")]), _c('div', {
    staticClass: "sub-item"
  }, [_vm._v("Participating in Industry Events")]), _c('div', {
    staticClass: "sub-item"
  }, [_vm._v("Opening NFT FreeMint")]), _c('div', {
    staticClass: "sub-item"
  }, [_vm._v("Listing $TapCoin on Exchanges")])])])])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "tit"
  }, [_vm._v("Join "), _c('span', [_vm._v("TapCoins")])]);
}];
export { render, staticRenderFns };